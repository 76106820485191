:root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --bg: rgb(44, 42, 54);
  --bg-bold: rgb(62, 59, 75);
  --bg-bolder: rgb(73, 70, 90);
  --bg-text: rgb(224, 224, 224);
  --bg-text-bold: rgb(255, 255, 255);
  --bg-text-light: rgb(179, 179, 179);
  --bg-text-lighter: rgb(73, 73, 73);
  --fg: rgb(31, 29, 40);
  --fg-bold: rgb(20, 15, 30);
  --fg-text: rgb(161, 159, 158);
  --fg-text-bold: rgb(244, 242, 254);

  --nav-height: 5rem;
  --nav-button-ratio: 0.6;
  --nav-button-size: calc(var(--nav-button-ratio)*var(--nav-height));
  --link-button-height: 40px;
  --link-button-padding: 0.5rem;

  --blue: rgb(13, 104, 207);
  --blue-bold: rgb(38, 122, 218);

  --border: 1px solid var(--bg-bold);
  --border-radius: 2rem;
  --speed: 200ms;
  --color-speed: 150ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}