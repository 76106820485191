html, 
body, 
div, 
span, 
object, 
iframe, 
h1, 
h2, 
h3, 
h4, 
h5, 
h6, 
p, 
blockquote, 
pre, 
abbr, 
address, 
cite, 
code, 
del, 
dfn, 
em, 
img, 
ins, 
kbd, 
q, 
samp, 
small, 
strong, 
sub, 
sup, 
var, 
b, 
i, 
dl, 
dt, 
dd, 
ol, 
ul, 
li, 
fieldset, 
form, 
label, 
legend, 
table, 
caption, 
tbody, 
tfoot, 
thead, 
tr, 
th, 
td, 
article, 
aside, 
canvas, 
details, 
figcaption, 
figure, 
footer, 
header, 
hgroup, 
menu, 
nav, 
section, 
summary, 
time, 
mark, 
audio, 
video{ margin:0; padding:0; border:0; outline:0; font-size:100%; vertical-align:baseline; background:transparent; }     

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary { display: block; }

audio,
canvas,
video { display: inline-block; *display: inline; *zoom: 1;}

audio:not([controls]) {display: none;height: 0;}

[hidden] { display: none; }

html { font-family: sans-serif; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

a:focus { outline: thin dotted; }

a:active,
a:hover { outline: 0; }

h1, h2, h3, h4, h5, h6 { font-weight:normal; }

abbr[title] { border-bottom: 1px dotted; }

b, strong { font-weight: bold; }

dfn, i, em { font-style: italic; }

li {list-style: none}

hr { -moz-box-sizing: content-box; box-sizing: content-box;height: 0; }

mark { background: #ff0; color: #000; }

code, kbd, pre, samp { font-family: monospace, serif; font-size: 1em; }

pre { white-space: pre-wrap; }

q { quotes: "\201C" "\201D" "\2018" "\2019"; }

small { font-size: 80%; }

sub, sup { position: relative; font-size: 75%; line-height: 0; vertical-align: baseline; }

sup { top: -0.5em; }

sub { bottom: -0.25em; }

img { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; }

svg:not(:root) { overflow: hidden; }

figure { margin: 0; }

fieldset { border: 1px solid #c0c0c0;margin: 0 2px;padding: 0.35em 0.625em 0.75em;}

legend { border: 0; padding: 0; }

button, input, select, textarea { font-family: inherit; font-size: 100%; margin: 0;  vertical-align: baseline; *vertical-align: middle;} 

button, input { line-height: normal; }

button, select { text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] { -webkit-appearance: button; cursor: pointer; }

button[disabled], html input[disabled] { cursor: default; }

input[type="checkbox"], input[type="radio"] { box-sizing: border-box; padding: 0; }

input[type="search"] { -webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

textarea { overflow: auto; vertical-align: top; }

table { border-collapse: collapse; border-spacing: 0; }

td { vertical-align: top; }